import { navigate } from 'gatsby';
import React from 'react';
import Container from '../components/atoms/container';
import Button from '../components/atoms/button';
import Typography from '../components/atoms/typography';
import Spacer from '../components/atoms/spacer';
import Routes from '../services/routes';
import styled from 'styled-components';
import { FULL_PAGE_HEIGHT } from '../services/sizeHelper';
import bgImage from '../images/panbox-familj.png';
import useScreenClassHelper from '../hooks/useScreenClassHelper';
import { resetPaymentDetails } from '../utils/localStorage';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  min-height: ${FULL_PAGE_HEIGHT};
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  margin-bottom: -46px;
  width: 100%;
`;

const ErrorPage = () => {
  const { IS_MOBILE } = useScreenClassHelper();

  const handleButtonClick = () => {
    resetPaymentDetails();
    navigate(Routes.LANDING_PAGE);
  };

  return (
    <Wrapper>
      <Spacer spacing={IS_MOBILE ? 4 : 15} />
      <StyledContainer>
        <Typography color="textSecondary" variant="header" align="center">
          Betalningen kunde inte slutföras. Vänligen försök igen...
        </Typography>
        <Spacer spacing={2} />
        <Button onClick={handleButtonClick}>Tillbaka till startsida</Button>
        <Spacer spacing={4} />
        <Image src={bgImage} alt="familj" />
      </StyledContainer>
    </Wrapper>
  );
};

export default ErrorPage;
